import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useReducer } from 'react';

import DeepstreamContext from '#contexts/DeepstreamContext';

import ProcessStatusOverviewStyles from './ProcessStatusOverviewStyles';
import useProvidersApi from './ProvidersApi/useProvidersApi';
import ProvidersTable from './ProvidersTable';

const useStyles = makeStyles(ProcessStatusOverviewStyles);

const presenceReducer = (list, action) => {
  switch (action.type) {
    case 'updateProvider': {
      const newList = [...list];
      const index = newList.findIndex(({ username }) => username === action.username);
      if (index >= 0) {
        newList[index].isOnline = action.isOnline;
      }
      return newList;
    }
    case 'setArray': {
      return action.array;
    }
    default:
      throw new Error();
  }
};

const ProcessStatusOverview = () => {
  const classes = useStyles();
  const { dsClient } = useContext(DeepstreamContext);
  const { isProvidersArrayLoading, providers } = useProvidersApi();
  const [providersPresence, dispatch] = useReducer(presenceReducer, []);

  const presenceHandler = (username, isOnline) => {
    dispatch({ type: 'updateProvider', username, isOnline });
  };

  useEffect(() => {
    dsClient.presence.subscribe(presenceHandler);
    return function cleanup() {
      dsClient.presence.unsubscribe(presenceHandler);
    };
  }, []);

  useEffect(() => {
    const getPresence = async () => {
      try {
        const usernames = await dsClient.presence.getAll();
        usernames.forEach((username) => {
          presenceHandler(username, true);
        });
      } catch (error) {
        //
      }
    };

    if (providers) {
      dispatch({ type: 'setArray', array: providers.map((item) => ({ ...item, isOnline: false, status: '-' })) });
      getPresence();
    }
  }, [providers]);

  return (
    <Card className={classes.Card}>
      <CardHeader title="Microservices" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
      <CardContent className={classes.CardContent}>
        {isProvidersArrayLoading ? 'Loading...' : <ProvidersTable rows={providersPresence} />}
      </CardContent>
    </Card>
  );
};

export default ProcessStatusOverview;
