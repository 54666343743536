export default (theme) => {
  return {
    Card: {
      borderRadius: 'unset',
      boxShadow: '0 0 13px 0 rgba(82,63,105,.1)',
      margin: [0, `${theme.spacing(2)}px`, `${theme.spacing(1)}px`, 0].join(' '),

      '&:last-child': {
        marginRight: 'unset',
      },
    },
    CardHeader: {
      padding: theme.spacing(2),
    },
    CardContent: {
      display: 'flex',
      padding: 'unset',
      paddingLeft: theme.spacing(1),
      '&:last-child': {
        paddingBottom: theme.spacing(1),
      },
      '&>*': {
        flex: 1,
        margin: [0, `${theme.spacing(1)}px`, `${theme.spacing(1)}px`, 0].join(' '),
        '&:first-child': {
          marginLeft: theme.spacing(2),
        },
        '&:last-child': {
          marginRight: theme.spacing(2),
        },
      },
    },
  };
};
