const Reducer = (state, action) => {
  switch (action.type) {
    case 'setProvidersLoading':
      return { ...state, isProvidersArrayLoading: action.isProvidersArrayLoading };
    case 'setProvidersArray':
      return { ...state, providersError: null, providers: action.providers };
    case 'setProvidersError':
      return {
        ...state,
        providers: [],
        providersError: action.providersError,
      };
    default:
      throw new Error();
  }
};

export default Reducer;
