import HTTPStatus from 'http-status';
import { useEffect, useReducer } from 'react';

import useAxios from '#hooks/useAxios';

import InitialState from './InitialState';
import { getProvidersQuery } from './Queries';
import Reducer from './Reducer';

const useProvidersApi = () => {
  const [providersState, dispatch] = useReducer(Reducer, InitialState);
  const { fetchData, isAxiosFetching: isProvidersArrayLoading } = useAxios();

  const getProviders = async () => {
    try {
      const { status, data } = await fetchData({ ...getProvidersQuery });
      if (status === HTTPStatus.OK) {
        dispatch({ type: 'setProvidersArray', providers: data });
      } else {
        dispatch({ type: 'setProvidersError', sessionError: { message: 'Wrong microservice API parameters' } });
      }
    } catch ({ message }) {
      dispatch({ type: 'setProvidersError', sessionError: { message } });
    }
  };

  useEffect(() => {
    dispatch({ type: 'setProvidersLoading', isProvidersArrayLoading });
  }, [isProvidersArrayLoading]);

  useEffect(() => {
    const fetch = async () => {
      await getProviders();
    };
    fetch();
  }, []);

  return { ...providersState };
};

export default useProvidersApi;
