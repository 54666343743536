const axiosConfig = {
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getProvidersQuery = {
  ...axiosConfig,
  url: process.env.GATSBY_AUTH_PROVIDERS_URL,
  method: 'GET',
};
