export default () => {
  return {
    thPresence: {
      width: 55,
    },
    thName: {
      width: 300,
    },
    thStatus: {
      width: 100,
    },
    TableRow: {
      height: 24,
    },
  };
};
