import { ColoredCircleIcon } from '@c3s/ui-components';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React from 'react';

import ProvidersTableStyles from './ProvidersTableStyles';

const useStyles = makeStyles(ProvidersTableStyles);

const ProvidersTable = ({ rows }) => {
  const classes = useStyles();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center" className={classes.thPresence}>
            Presence
          </TableCell>
          <TableCell align="left" className={classes.thName}>
            Name
          </TableCell>
          <TableCell align="left">Description</TableCell>
          <TableCell align="center" className={classes.thStatus}>
            Status
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map(({ isOnline, username, name, status }, i) => (
          // TODO: Consider using a real ID as key
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={i} className={classes.TableRow}>
            <TableCell align="center">
              <ColoredCircleIcon status={isOnline ? 'success' : 'danger'} />
            </TableCell>
            <TableCell align="left">{username}</TableCell>
            <TableCell align="left">{name}</TableCell>
            <TableCell align="center">{status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

ProvidersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      isOnline: PropTypes.bool.isRequired,
      username: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string,
    }),
  ).isRequired,
};

export default ProvidersTable;
