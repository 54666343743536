import React from 'react';
import { Helmet } from 'react-helmet';

import ProcessStatusOverview from '#services/process-manager/ProcessStatusOverview';

const PageTitle = 'Monitoring';

const MicroservicePage = () => {
  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <div>
        <ProcessStatusOverview />
      </div>
    </>
  );
};

export default MicroservicePage;
